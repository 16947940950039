<template>
  <div class="container-box">
    <div class="title-box">
      <div class="line"></div>
      <span class="title">{{ title }}</span>
    </div>
    <div class="subject-box" v-if="Object.keys(details).length > 0">
      <div style="font-weight: 700">题目：</div>
      <el-scrollbar height="100%">
        <div class="mian-text">{{ details.topic_data.question }}</div>
      </el-scrollbar>
    </div>
    <div class="main-box" v-if="Object.keys(details).length > 0">
      <div class="content-box" v-for="(item, i) in ['diagnose', 'optimize']">
        <div style="font-weight: 700">{{ item === "diagnose" ? "数据分析" : "优化建议" }}</div>
        <div class="topic-answer">
          <div style="font-weight: 700; margin-bottom: 12px">参考：</div>
          <el-scrollbar height="100%">
            <el-table :data="details.topic_data[`${item}_standard`]" style="width: 100%" :header-cell-style="{ background: '#f0f2f5', color: '#333' }" :height="200">
              <el-table-column prop="data" label="序号" width="50" type="index"></el-table-column>
              <el-table-column prop="content" label="考核内容"></el-table-column>
              <el-table-column prop="mark" label="评分标准">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="scope.row.mark" placement="top">
                    <span class="two-text">{{ scope.row.mark }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="score" label="分值" width="50"></el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div class="student-answer">
      <div class="answer-title">
        <span>学生回答：</span>
        <span style="float: right"
          ><span style="color: #999">{{ `（满分${details.topic_data.total_score}分）` }}</span
          >评分</span
        >
      </div>
      <div class="answer-content">
        <div class="student-answer-item" v-for="(item, i) in details.student_answer_data">
          <div class="answer">
            <div :class="item.dataExpand ? 'mian-text two-text' : 'mian-text'">{{ !item.data_analysis ? "数据诊断：该学生未作答！" : `数据诊断：${item.data_analysis}` }}</div>
            <div class="mian-text" style="color: #1122d8; cursor: pointer" @click="item.dataExpand = !item.dataExpand" v-if="!!item.data_analysis">{{ item.dataExpand ? "展开" : "收起" }}</div>
            <div :class="item.problemExpand ? 'mian-text two-text' : 'mian-text'">{{ !item.problem_diagnosis ? "优化建议：该学生未作答！" : `优化建议：${item.problem_diagnosis}` }}</div>
            <div class="mian-text" style="color: #1122d8; cursor: pointer" @click="item.problemExpand = !item.problemExpand" v-if="!!item.problem_diagnosis">{{ item.problemExpand ? "展开" : "收起" }}</div>
          </div>
          <el-input-number :min="0" :max="details.topic_data.total_score" v-model="item.score" :precision="1" v-if="type === 'score'" controls-position="right" size="small" style="width: 94px; margin: 12px" :controls="false" :disabled="!item.data_analysis && !item.problem_diagnosis"></el-input-number>
          <div v-if="type === 'look'" style="padding-right: 24px">分值：{{ item.score }}</div>
        </div>
      </div>
      <div class="answer-btn" v-if="type === 'score'">
        <el-button type="primary" size="small" style="float: right; background: #1122d8; border: 1px solid #1122d8" @click="onAffirmScore()">确认评分</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getScoreList, postJudgeScore } from "../../../utils/apis";

export default {
  name: "Score",
  data() {
    return {
      details: {},
      type: void 0,
      title: void 0,
      tableData: [],
    };
  },
  methods: {
    async getScoreData() {
      let res = await getScoreList({ exam_id: this.$route.query.exam_id, tmpl_module_type: this.$route.query.tmpl_module_type, tmpl_module_id: this.$route.query.tmpl_module_id });
      if (res.code === 200) {
        Object.keys(res.data.student_answer_data).map((o) => {
          !res.data.student_answer_data[o].data_analysis && !res.data.student_answer_data[o].problem_diagnosis ? (res.data.student_answer_data[o].score = 0) : !!res.data.student_answer_data[o].score ? (res.data.student_answer_data[o].score = res.data.student_answer_data[o].score) : (res.data.student_answer_data[o].score = void 0);
          res.data.student_answer_data[o].dataExpand = false;
          res.data.student_answer_data[o].problemExpand = false;
        });
        this.details = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    onAffirmScore() {
      let score = [];
      Object.keys(this.details.student_answer_data).map((o) => {
        score.push({ student_id: this.details.student_answer_data[o].student_id, score: this.details.student_answer_data[o].score });
      });
      postJudgeScore({
        exam_id: this.$route.query.exam_id,
        tmpl_module_id: this.$route.query.tmpl_module_id,
        tmpl_module_type: this.$route.query.tmpl_module_type,
        data: score,
      })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: res.code === 200 ? "success" : "error",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.title = this.$route.query.title;
    this.getScoreData();
  },
};
</script>

<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  padding: 36px 10px 36px 50px;
  color: #333;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  .title-box {
    display: flex;
    align-items: center;
    .line {
      width: 4px;
      height: 20px;
      background: #1122d8;
      border-radius: 10px;
      margin-right: 8px;
    }
    .title {
      color: #333333;
      font-weight: 700;
      font-size: 16px;
    }
  }
  .subject-box {
    height: 80px;
    padding: 20px;
    background: #f0f2f5;
    margin-top: 20px;
    border-radius: 4px;
    margin-right: 40px;
  }
  .main-box {
    display: flex;
    .content-box {
      flex: 1;
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 40px;
    }
    .topic-answer {
      margin: 20px 0;
    }
  }
  .student-answer {
    height: calc(100% - 520px);
    background: #f0f2f5;
    padding: 20px;
    border-radius: 4px;
    // display: flex;
    // flex-direction: column;
    margin-right: 40px;
    .answer-title {
      height: 20px;
      font-weight: 700;
    }
    .answer-content {
      height: calc(100% - 72px);
      overflow-y: auto;
      .student-answer-item {
        min-height: 60px;
        padding: 16px 0;
        font-size: 12px;
        border-bottom: 1px solid #dcdfe6;
        display: flex;
        justify-content: space-between;
        .answer {
          flex: 1;
        }
      }
    }
    .answer-btn {
      height: 32px;
      margin-top: 20px;
    }
  }
  .mian-text {
    font-size: 12px;
    margin-top: 10px;
    // height: 48px;
  }
  .two-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
</style>
